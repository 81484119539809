.mat-mdc-icon-button .mat-mdc-button-wrapper > *,
.mat-menu-item .mat-icon,
.mat-mdc-button .mat-icon {
  vertical-align: middle !important;
}

.mat-mdc-select-panel, .mat-mdc-autocomplete-panel, .mat-mdc-menu-panel, .mat-mdc-card {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background: #{$light-primary-text};
}

.mat-mdc-button.mat-primary  {
    color: $btn-primary !important;
}

.mat-mdc-form-field-hint, .mat-mdc-floating-label {
  .mat-mdc-icon-button .mat-icon {
    font-size: inherit;
  }
}

.mat-mdc-table {
  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    border-bottom-color: var(--foreground-divider);

    .mat-icon.mat-primary {
      color: $btn-primary;
    }
  }
}


.mat-mdc-select-arrow-wrapper {
  display: block !important;
}

.mat-mdc-form-field-type-mat-select {
  height: 80px;
}

.mat-mdc-text-field-wrapper{
  background-color: #{$odd-row} !important;
  border-radius: 4px 4px 0 0 !important;
  padding-top: 18px !important;
  padding: .25em .75em 0 .75em !important;
  

  &:hover {
      
      .mdc-line-ripple::after {
          border-bottom-width: 2px;
          opacity: 1;
          border-color: $btn-primary;
          border-style: solid;
          transform: none;
      }
  } 
}

.mat-mdc-form-field {
  margin-bottom: 4px;

  .mat-mdc-form-field-icon-prefix {

    align-self: flex-start;

    .mat-icon {
      padding: 8px 0 0 0;
    }
  }

  .mat-mdc-form-field-infix {
    display: flex;
    flex-direction: row;
    min-height: 48px;
    padding-top: 18px !important;
  }

  &.can-grow {
    .mat-mdc-text-field-wrapper {
      flex: 1 0 auto !important;
      height: auto;
      overflow: auto;
    }

    .mat-mdc-text-field-wrapper  {
      .mat-mdc-form-field-input-control {
        height: 50px;
      }
    }
  }

  &.mat-mdc-form-field-type-mat-chip-grid {
    .mat-mdc-text-field-wrapper {
      flex: 1 0 auto !important;

      .mat-mdc-chip-action::before {
        border-color: var(--text-hint);
      }
    }
  }
}

.mat-mdc-paginator-page-size-select {
  height: 50px;
  .mat-mdc-text-field-wrapper {
    border-bottom: 1px solid var(--dark-text);
  }
}


.mdc-line-ripple {
  &::after {
      border-color: $dark-primary-text;
      border-style: solid;
      opacity: 1;
      border-bottom-width: 1px;
      transform: none !important;
  }

  &.mdc-line-ripple--active::after 
  {
      border-color: $btn-primary;
      opacity: 1;
      border-style: solid;
      transform: none;
  }

  &.mdc-line-ripple--deactivating::after {
      border-color: $dark-primary-text;
      border-bottom-width: 1px;
      opacity: 1;
      border-style: solid;
      transform: none;
  }
}

.mat-mdc-form-field {
  .mat-mdc-floating-label {
    top: 18px !important;
    transition: var(--trans-ease-out);
  }

  &.mat-focused .mat-mdc-floating-label
  {
    color: var(--btn-primary);
  }
}

.mat-mdc-form-field.mat-form-field-invalid {
  .mat-mdc-floating-label,
  .mat-mdc-form-field-hint {
    color: var(--color-warn);
  }
}

.mat-mdc-table .mat-mdc-cell,
.mat-mdc-table .mat-mdc-header-cell {
  box-sizing: content-box;
  padding-left: var(--padding-12);
  padding-right: var(--padding-12);
  white-space: nowrap;
}

.mat-mdc-paginator-page-size-select.mat-mdc-form-field .mat-mdc-form-field-flex {
  padding-top: 0;
}

.mat-mdc-menu-item ic-icon {
  margin-inline-end: var(--padding-16);
  vertical-align: middle;

  > svg {
    vertical-align: middle;
  }
}

.mat-warn:not(:disabled) {
  color: var(--btn-red) !important;
}

.mat-mdc-select-panel {
  //@apply text-base;
}

.mat-mdc-dialog-content {
  // padding: 0 !important;
}

.mat-toolbar {
  font: 500 18px / 26px var(--font);
  letter-spacing: -.014em;
  margin: 0;

  &.mat-primary {
    background: #00b5e6;
    color: #fff;
  }
}

.mdc-notched-outline {
  &>div {
    border: none;
  }
}

.mat-mdc-tab-header {
  border-bottom: 1px solid var(--foreground-divider);
}

.mat-pseudo-checkbox {
  border-color: var(--mdc-checkbox-selected-icon-color) !important;
  &.mat-pseudo-checkbox-checked {
    border-color: var(--mdc-checkbox-selected-icon-color) !important;
    background-color: var(--mdc-checkbox-selected-icon-color) !important;
  }
  &.mat-pseudo-checkbox-indeterminate {
    background-color: var(--mdc-checkbox-intermediate-icon-color) !important;
  }
  &::after {
    color: white;
  }
}


.mat-mdc-tab-header-pagination-before {
  //border-right: 1px solid var(--foreground-divider);
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.mat-mdc-tab-header-pagination-after {
  //border-left: 1px solid var(--foreground-divider);
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: black;
}

.mat-mdc-dialog-actions {
  padding: 0px 24px !important;
}

.mat-drawer-side {
  border: 0;
}

.mat-mdc-input-element {
  color: $dark-primary-text

}

textarea.mat-mdc-input-element {
  @apply leading-normal;
}

.vex-flex-form-field {
  .mat-mdc-form-field-infix {
    width: 50px;
  }
}

.vex-dense-form-field {
  margin-bottom: -1.34375em;

  &.mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      padding-top: 4px;
    }
  }
}


.cdk-overlay-pane {
  .mat-datepicker-content {
      box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
      background: #{$light-primary-text};
      //background-color: #{$bg};
  }
}

.mat-mdc-unelevated-button.mat-primary, 
.mat-mdc-raised-button.mat-primary, 
.mat-mdc-fab.mat-primary, 
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-paginator .mat-mdc-paginator-range-actions .paginator-btn {
  background-color: $btn-background !important;
  color: $light-primary-text !important;
}



.mat-mdc-dialog-container {
  display: block;

}



.mat-mdc-card-content {
  padding: 0 !important;
}

.mat-mdc-dialog-title {
  margin: 20px 0px 0px 20px !important;

  .mat-mdc-icon-button {
      align-self: start;
  }
}

.mdc-form-field
{
  &>label {
    color: #{$dark-primary-text};
  }

}

.mat-mdc-card-title {
  font-weight: bold;
  color: #{$dark-primary-text};
}

.mat-calendar-body-cell {

  .mat-calendar-body-cell-content {
    &:hover {
      background-color: var(--foreground-divider);
    }
  }
}

.mdc-list-item {
  &--selected {
    color: var(--btn-primary);
    background: rgba(0,0,0,.12);
  } 
}

// .vex-tabs {
//   /*
// TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
// */
//   .mdc-tab__text-label.mdc-tab__text-label-active {
//     opacity: 1;
//   }
//   /*
// TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
// */
//   .mat-mdc-tab-link.mdc-tab__text-label-active {
//     opacity: 1;
//   }
// }

// .vex-tabs-dense {
//   /*
// TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
// */
//   .mdc-tab__text-label {
//     min-width: 0;
//   }
// }

.uppercase > * > .mat-sort-header-button {
  text-transform: uppercase;
}

.mat-mdc-card-image {
  max-width: none;
}

.mdc-tab__text-label {
  color: var(--text-secondary);
}

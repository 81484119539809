@import "var";

.vex-style-my-tasks {
  // Foreground
  --background-app-bar: #{$page-mytasks};

  // Background
  // --footer-background: $page-overview;
  // --navigation-background: #{$page-overview};
  // --toolbar-background: #{$page-overview};
  // --background-base: #{$page-overview};

  // // Colors
  // --background-card: #{$page-overview};
  // --footer-color: $page-overview;
  // --navigation-color: $page-overview;
  // --text-color: #{$page-overview};
  // --toolbar-color: #{$page-overview};
  // --text-color-light: #{$page-overview};

  // // Toolbar
  // --foreground-divider: #{$page-overview};
  // --text-hint: #{$page-overview};

  // // Navigation
  // --text-hint-light: #{$page-overview};
  // --background-hover: #{$page-overview};

  // // Secondary Toolbar
  // --text-secondary: #{$page-overview};

  // // Footer
  // --text-secondary-light: #{$page-overview};
  // --secondary-toolbar-background: $page-overview;
}

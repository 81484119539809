//@use "../node_modules/simplebar/dist/simplebar.css";
@use "../node_modules/highlight.js/scss/androidstudio.scss";
@use "./@vex/styles/core";
@use "./@vex/styles/_default/var";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");


.btn-progress {
  display: inline-block;
  position: absolute;
  right: 8px;
  top: 8px;
}
mat-icon {
  font-family: 'Material Icons';
}

ngx-mat-timepicker {
  .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    padding: 0 !important; 
  }
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }
}

.message-icon {
  transform: scale(0.66);
  height: 10px;

  .read {
    color: --sidenav-item-color;
    opacity: .3;
  }

  .unread {
    color: var(--btn-primary);
  }
}

.message-icon-header
{
  padding-left: 6px;
  vertical-align: middle;
  .unread {
    color: var(--btn-primary);
  }
}

.mdc-tab__content {
  .unread {
    color: var(--btn-primary);
  }
}


.select-all {
  &.mat-mdc-option {
    background-color: var(--foreground-divider);
    padding-left: 5px;

    .mdc-checkbox__background {
      border-color: var(--btn-primary);
    }
  }
}